import React, { memo } from 'react';
import { Button, Text, VStack } from '@chakra-ui/react';

import { goTo } from 'shared/lib/goTo';

import { FORM_ID } from 'constants/global';
import { ym } from 'shared/lib/ym';

const Greetings = ({
  title,
  subTitle = null,
  titleSx = null,
  subTitleSx = null,
  btnSx = null,
  titlesContainerSx = null,
  metricName = null,
  ...props
}) => {
  return (
    <VStack
      w="full"
      spacing={{ mobile: '48px', tablet: '64px', laptop: '80px' }}
      align="start"
      pos="relative"
      zIndex="800"
      mb={{ mobile: '180px', tablet: '200px', laptop: '210px' }}
      {...props}
    >
      <VStack
        w="full"
        spacing={{ mobile: '24px', tablet: '32px', laptop: '40px' }}
        align="start"
        {...titlesContainerSx}
      >
        <Text
          as="h1"
          w="full"
          maxW={{ tablet: '395px', laptop: '1130px', desktop: '780px' }}
          textAlign="left"
          {...titleSx}
        >
          {title}
        </Text>
        {subTitle && (
          <Text textStyle="h1" {...subTitleSx}>
            {subTitle}
          </Text>
        )}
      </VStack>

      <Button
        size="primary"
        onClick={() => {
          goTo(FORM_ID);
          metricName && ym(metricName);
        }}
        {...btnSx}
      >
        Оставить заявку
      </Button>
    </VStack>
  );
};

export default memo(Greetings);
