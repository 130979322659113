export const mainGreetingsData = {
  title: 'Трудный путь к вершине',
  subTitle: 'Эффективные ИT-решения для развития бизнеса',
  titleSx: { maxW: { xxl: '780px' }, fontSize: { xxl: '88px', tablet: '48px', mobile: '40px' } },
  subTitleSx: { maxW: { mobile: '310px', tablet: 'unset' } },
  titlesContainerSx: { spacing: { mobile: '20px', tablet: '40px' } },
  btnSx: {
    fontSize: { laptop: '24px', mobile: '18px' },
    lineHeight: '120%',
    letterSpacing: { tablet: '-0.02em' },
  },
};

export const consultingGreetingsData = {
  title: 'Комплексный ИТ\u2011консалтинг',
  btnSx: {
    p: { laptop: '32px 48px', mobile: '20px 32px' },
    fontSize: { laptop: '24px', tablet: '18px', mobile: '14px' },
  },
};

export const supportGreetingsData = {
  title: 'Комплексная поддержка и ИТ\u2011услуги',
  titleSx: { maxW: { mobile: '400px', tablet: '500px', laptop: '940px' } },
  mb: { laptop: '196px', tablet: '110px', mobile: '163px' },
  metricName: 'it-support_goto_form',
};

export const bodyshopGreetingsData = {
  title: 'Талантливые ИТ-специалисты для вашего проекта',
  titleSx: { maxW: { laptop: '940px' } },
};

export const dedicatedTeamGreetingsData = {
  title: 'Выделенная команда разработки',
  subTitle: 'Быстрое погружение в специфику вашего бизнеса',
  titleSx: {
    maxW: { laptop: '940px', tablet: '700px', mobile: '400px' },
    color: 'text.black',
  },
  btnSx: {
    textStyle: 'button.tertiary',
    fontSize: { laptop: '24px', tablet: '20px', mobile: '14px' },
    lineHeight: { tablet: '120%', mobile: '100%' },
  },
};

export const mdsGreetingsData = {
  title: 'Проектная разработка под ключ',
  titleSx: { maxW: { laptop: '940px', mobile: '280px' }, color: '#23281F' },
  btnSx: { fontSize: { laptop: '24px', mobile: '18px' } },
};

export const fintechGreetingsData = {
  title: 'Разработка финансового ПО',
  titleSx: { maxW: { laptop: '940px', mobile: '400px' }, color: 'text.black' },
  btnSx: { fontSize: { laptop: '24px', mobile: '18px' } },
};
