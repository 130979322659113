import React from 'react';
import { Box } from '@chakra-ui/react';

import WhyUs from 'widgets/WhyUs';
import Reviews from 'widgets/Reviews';
import Bullets from 'widgets/Bullets';
import Projects from 'widgets/Projects';
import Greetings from 'widgets/Greetings';
import ContactUs from 'widgets/ContactUs';
import Companies from 'widgets/Companies';
import TechExpert from 'widgets/TechExpert';
import Specialists from 'widgets/Specialists';
import Cooperation from 'widgets/Cooperation';
import PageMetaData from 'widgets/PageMetaData';
import ExpertiseCardsSlider from 'widgets/ExpertiseCardsSlider';
import BlockHeader from 'shared/BlockHeader';
import Wrapper from 'shared/Wrapper';

import { GREETINGS_GAP } from 'constants/global';
import { projectsData } from 'constants/bodyshop/projects';
import { dedicatedTeamBulletsData } from 'constants/Bullets';
import { STORAGE_URL_ASTONDEVS } from 'constants/Api/constants';
import { dedicatedTeamGreetingsData } from 'constants/Greetings';
import { dedicatedTeamContactUsData } from 'constants/contactUs';
import { dedicatedTeamCooperationData } from 'constants/cooperations';
import { dedicatedTeamExpertiseCardsSliderData } from 'constants/expertiseCardsSlider';

export default function DedicatedTeamPage() {
  return (
    <>
      <PageMetaData
        title="Выделенные команды разработки — ASTON"
        description="Сформированные команды ИТ-специалистов всех направлений для вашего проекта. Всего 10 дней для начала работы."
        img={{
          src: `${STORAGE_URL_ASTONDEVS}/metaPreviews/dedicated-team.png`,
          alt: 'Превью для страницы Выделенная команда',
        }}
      />
      <Wrapper pt={{ desktop: '170px', laptop: '173px', mobile: '120px' }}>
        <Box w="full" pos="relative" zIndex="800" gap={GREETINGS_GAP}>
          <Greetings {...dedicatedTeamGreetingsData} />
          <Bullets {...dedicatedTeamBulletsData} />
        </Box>
        <ExpertiseCardsSlider {...dedicatedTeamExpertiseCardsSliderData} />
        <TechExpert />
        <Companies />
        <Specialists />
        <WhyUs />
        {/* @ts-ignore */}
        <Projects
          HeaderRender={<BlockHeader>Реализованные проекты</BlockHeader>}
          {...projectsData}
        />
        <Reviews />
        <Cooperation {...dedicatedTeamCooperationData} />
        <ContactUs {...dedicatedTeamContactUsData} />
      </Wrapper>
    </>
  );
}
