import reward from 'assets/Icons/reward.svg';
import star from 'assets/Icons/Star.svg';
import handUp from 'assets/Icons/handUp.svg';
import cubes4 from 'assets/Icons/cubes_4.svg';

const boxSizeWhyUs = {
  maxW: { mg: '670px', mobile: '340px' },
  h: { desktop: '480px', laptop: '420px', mobile: '340px' },
};
export const whyUsData = [
  {
    icon: star,
    title: 'Большой выбор ролей и технологий',
    iconProps: { bgColor: 'bg.gray' },
    description:
      'В команде Aston — специалисты с глубокой доменной экспертизой и большим опытом работы, который мы готовы применить на ваших проектах',
    sx: { bgColor: 'bg.white', border: '1.6px solid', borderColor: 'bg.gray', ...boxSizeWhyUs },
  },
  {
    icon: handUp,
    title: 'Максимальное погружение',
    description:
      'Наши специалисты не просто выполняют поставленную задачу, а подбирают лучшие инструменты с учётом особенностей конкретного бизнеса',
    sx: { bgColor: 'bg.gray', ...boxSizeWhyUs },
  },
  {
    icon: cubes4,
    title: 'Возможность работы над проектом в вашей экосистеме',
    description:
      'Мы готовы работать на удобных для вас платформах и придерживаться комфортного для вас типа рабочего процесса',
    sx: { bgColor: 'bg.neonLight', ...boxSizeWhyUs },
  },
  {
    icon: reward,
    title: 'Сокращение издержек',
    description:
      'Вы платите только за работу команды и не тратите бюджет на аренду офиса, подбор и обучение персонала, амортизацию оборудования',
    sx: { bgColor: 'bg.grayLight', ...boxSizeWhyUs },
  },
];
